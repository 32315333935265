<template>
    <div class="flex flex-1 flex-col card card-f-h">
        <ConfirmationModal codeConfirmation @success="actionSuccess" />

        <ModalAddKey
            :visible="modalAddKeyVisible"
            :clientUuid="clientUuid"
            @refresh="actionSuccess"
            @close="modalAddKeyVisible = false"
        />

        <ModalKeyHistory
            :keyUuid="selectedKeyUuid"
            :visible="modalKeyHistoryVisible"
            @close="modalKeyHistoryVisible = false"
        />

        <ModalEditHolder
            :visible="modalEditHolderVisible"
            :keyUuid="selectedKeyUuid"
            @refresh="getAll"
            @close="modalEditHolderVisible = false"
        />

        <ModalEditKey
            :visible="modalEditKeyVisible"
            :keyUuid="selectedKeyUuid"
            @refresh="getAll"
            @close="modalEditKeyVisible = false"
        />

        <header class="flex items-center justify-between h-10 border-b border-gray-200 pb-4 mb-5">
            <p>
                {{ $t('clients.keys') }} ({{ keysData.length }})
            </p>
            <el-button type="primary" :disabled="!userCan('create keys')" @click="showAddModal">
                {{ $t('common.add') }}
            </el-button>
        </header>

        <Table :tableData="keysData" @handleDelete="handleDelete" @handleKeyHistory="handleKeyHistory" @handleEditHolder="handleEditHolder" @handleEdit="handleEditKey" />

        <InitialLoader v-if="$wait.is('loading.initial_*')" />

        <NoDataInformation :data="noDataInformation" waitKey="loading" />
    </div>
</template>
<script>
import Api from './keys.api';

export default {
    components: {
        ModalAddKey:     () => import(/* webpackChunkName: "Client/ModalAddKey" */ './KeysModalAddKey'),
        ModalKeyHistory: () => import(/* webpackChunkName: "Client/ModalKeyHistory" */ './KeysModalKeyHistory'),
        ModalEditHolder: () => import(/* webpackChunkName: "Client/ModalEditHolder" */ './KeysModalEditHolder'),
        ModalEditKey:    () => import(/* webpackChunkName: "Client/ModalEditKey" */ './KeysModalEditKey'),
        Table:           () => import(/* webpackChunkName: "Client/ClientKeysTable" */ './KeysTable'),
    },

    data() {
        return {
            clientUuid:             this.$route.params.uuid,
            keysData:               [],
            modalAddKeyVisible:     false,
            modalKeyHistoryVisible: false,
            modalEditHolderVisible: false,
            modalEditKeyVisible:    false,
            noDataInformation:      false,
            selectedKeyUuid:        null,
        };
    },

    beforeCreate() {
        this.$wait.start('loading.initial_keys');
    },

    created() {
        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading_keys');
            try {
                const data = await Api.getAll(this.clientUuid);
                this.keysData = data;
                this.noDataInformation = data.length;
            } finally {
                this.$wait.end('loading_keys');
                this.$wait.end('loading.initial_keys');
            }
        },

        showAddModal() {
            this.modalAddKeyVisible = true;
        },

        handleKeyHistory(uuid) {
            this.selectedKeyUuid = uuid;
            this.modalKeyHistoryVisible = true;
        },

        handleEditHolder(uuid) {
            this.selectedKeyUuid = uuid;
            this.modalEditHolderVisible = true;
        },

        handleEditKey(uuid) {
            this.selectedKeyUuid = uuid;
            this.modalEditKeyVisible = true;
        },

        handleDelete(uuid) {
            this.$store.commit('showConfirmationModal', {
                actionUrl:  Api.destroyURL(uuid),
                actionType: 'destroy',
            });
        },

        actionSuccess() {
            this.getAll();
            this.$emit('clientDataChanged');
        },
    },
};
</script>
