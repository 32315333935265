export default {
    async getAll(clientUuid) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientUuid}/keys`, {
                requestId: 'justRequest',
            });
            return data;
        } catch (error) {
            console.log('🚀 ~ file: keys.api.js ~ getAll ~ error', error);
            throw error;
        }
    },

    async getAllAddresses(clientUuid) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/clients/${clientUuid}/addresses`, {
                requestId: 'forAxiosCancel',
            });
            return data;
        } catch (error) {
            console.log('🚀 ~ file: keys.api.js ~ getAllAddresses ~ error', error);
            throw error;
        }
    },

    async createKey(keyData) {
        try {
            const { data } = await axios.post(`${process.env.VUE_APP_CORE_API_URL}/clients/keys`, {
                name:         keyData.name,
                description:  keyData.description,
                address_uuid: keyData.addressUuid,
            });
            return data;
        } catch (error) {
            console.log('🚀 ~ file: keys.api.js ~ createKey ~ error', error);
            throw error;
        }
    },

    async uploadImage(keyUuid, imageData) {
        try {
            await axios.post(`${process.env.VUE_APP_CORE_API_URL}/clients/keys/${keyUuid}/upload_image`,
                imageData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
        } catch (error) {
            console.log('🚀 ~ file: keys.api.js ~ uploadImage ~ error', error);
            throw error;
        }
    },

    async getKeyHistory(uuid) {
        try {
            const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/clients/keys/${uuid}`, {
                requestId: 'justRequest',
            });
            return data;
        } catch (error) {
            console.log('🚀 ~ file: keys.api.js ~ getKeyHistory ~ error', error);
            throw error;
        }
    },

    destroyURL(uuid) {
        return `${process.env.VUE_APP_CORE_API_URL}/clients/keys/${uuid}/force_delete`;
    },
};
